import React, { useEffect, useLayoutEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { HashRouter, useHistory, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'

import './App.css'
import store from './store'
import Routes from './routes'
import themeShipay from './styles/theme'
import themeOrange from './styles/theme-rede'
import themeSpacePay from './styles/theme-ndd'

import GlobalStyle from './styles/global'
import { Provider } from 'react-redux'
import { baseURL } from './services/apiConfig'
import { isConexaoItau, isSpacePay, isWhiteLabel } from 'helpers'

import useGetTheme from 'hooks/useGetTheme'

import faviconredeitau from './assets/images/favicon-redeitau.png'
import faviconNddSpace from './assets/images/favicon-ndd.png'
import WhiteLabelLoading from 'components/Loading/WhiteLabel'
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import IdleModal from 'blocks/IdleModal'
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
} from 'helpers/localStorage'
import { removeWithCredentials } from 'services/api'
import useHotjarTracking from 'hooks/useHotjarTracking'

function App() {
  const [theme, setTheme] = React.useState(themeShipay)
  const [idleWarning, setIdleWarning] = React.useState(false)
  const [isIdle, setIsIdle] = React.useState(false)
  const { userTheme, getTheme, hasError, hasSuccess, isLoading } = useGetTheme()
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    !!getValueFromLocalStorage('currentUserSession')
  )

  const handleTitleName = (env: string, theme: string) => {
    const title = document.getElementById('title')
    let tabName = `${theme} - ${env}`
    title.firstChild.textContent = tabName
  }

  useLayoutEffect(() => {
    if (isWhiteLabel() && theme.domain === 'Shipay') {
      getTheme()
    }
  }, [])

  useLayoutEffect(() => {
    if (isConexaoItau()) {
      setTheme(themeOrange)
      const icon = document.getElementById('favicon') as HTMLLinkElement
      const appleIcon = document.getElementById(
        'apple-favicon'
      ) as HTMLLinkElement
      icon.href = faviconredeitau
      appleIcon.href = faviconredeitau
      const title = document.getElementById('title')
      title.firstChild.textContent = 'ConexãoItaú'
    }

    if (isSpacePay()) {
      setTheme(themeSpacePay)
      const icon = document.getElementById('favicon') as HTMLLinkElement
      const appleIcon = document.getElementById(
        'apple-favicon'
      ) as HTMLLinkElement
      icon.href = faviconNddSpace
      appleIcon.href = faviconNddSpace
      const title = document.getElementById('title')
      title.firstChild.textContent = 'spacePay'
    }

    if (
      !baseURL.includes('localhost') &&
      !baseURL.includes('sandbox') &&
      !baseURL.includes('quality') &&
      !baseURL.includes('staging') &&
      !isSpacePay()
    ) {
      const scriptFreshworks = document.createElement('script')
      scriptFreshworks.type = 'text/javascript'
      scriptFreshworks.async = true
      scriptFreshworks.src = '//fw-cdn.com/11101685/3830472.js'
      scriptFreshworks.setAttribute('chat', 'true')

      if (isConexaoItau()) {
        scriptFreshworks.setAttribute(
          'widgetId',
          'fd2e8f5a-02c4-41e7-9da5-2827a71fe550'
        )
      }
      document.body.appendChild(scriptFreshworks)

      return () => {
        document.body.removeChild(scriptFreshworks)
      }
    }
  }, [])

  useEffect(() => {
    if (userTheme.pageTitle && userTheme.images.favicon) {
      setTheme(userTheme)
      const icon = document.getElementById('favicon') as HTMLLinkElement
      const appleIcon = document.getElementById(
        'apple-favicon'
      ) as HTMLLinkElement
      icon.href = userTheme.images.favicon
      appleIcon.href = userTheme.images.favicon
      if (window['env']['name'] !== 'production') {
        handleTitleName(window['env']['name'], userTheme.pageTitle || 'Shipay')
      }
    }
  }, [userTheme.pageTitle, userTheme.images])

  useEffect(() => {
    function listenForStorage() {
      const currentUser = !!getValueFromLocalStorage('currentUserSession')
      setIsLoggedIn(currentUser)
    }
    window.addEventListener('storage', listenForStorage)

    const title = document.getElementById('title')
    if (window['env']['name'] !== 'production') {
      handleTitleName(
        window['env']['name'],
        title.firstChild.textContent || 'Shipay'
      )
    }
    return () => {
      window.removeEventListener('storage', listenForStorage)
    }
  }, [])

  const onAction = (event: Event, idleTimer: IIdleTimer) => {
    if (idleTimer.isPrompted()) {
      idleTimer.activate()
    }
  }

  const onActive = (event: Event, idleTimer: IIdleTimer) => {
    if (idleTimer.isPrompted()) {
      setIdleWarning(false)
    }
  }

  const onIdle = async (event: Event) => {
    await removeWithCredentials(`${baseURL}/v1/auth-session/token`)
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        removeValueFromLocalStorage('currentUserSession')
        removeValueFromLocalStorage('adminUserSession')
        if (window.gtag) {
          window.gtag('event', 'page_view', {
            page_path: 'modal_idle_user',
          })
        }
        setIsIdle(true)
      })
  }

  const handleClose = () => {
    setIsIdle(false)
    setIdleWarning(false)
  }

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 15,
    throttle: 500,
    disabled: !isLoggedIn,
    crossTab: true,
  })

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link
            href="./assets/fonts/Inter-Regular.ttf"
            rel="stylesheet"
            as="font"
          />
        </Helmet>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GridThemeProvider gridTheme={theme.grid}>
              {(isLoading ||
                (isWhiteLabel() &&
                  theme.domain === 'Shipay' &&
                  !window.location.hash.includes('login') &&
                  !window.location.hash.includes('reset-password'))) &&
              !hasError ? (
                <>
                  <WhiteLabelLoading />
                </>
              ) : (
                <HashRouter>
                  <GlobalStyle />
                  <IdleModal
                    isOpen={isIdle || idleWarning}
                    handleClose={handleClose}
                    type={isIdle ? 'isIdle' : 'idleWarning'}
                  />

                  <Routes setTheme={setTheme} />
                </HashRouter>
              )}
            </GridThemeProvider>
          </ThemeProvider>
        </Provider>
      </HelmetProvider>
    </>
  )
}

export default App
