import React, { useEffect } from 'react'
import * as s from './styles'
import { isNotEmpty } from 'helpers/validators'
import { applyCnpjMask, formatMoney } from 'helpers/masks'
import {
  baseURLRegistrationSummarySplitFee,
  baseURLRegistrationV1SplitFee,
  get,
  post,
} from 'services/api'
import Loading from './Loading'
import Success from './Success'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

export const DefaultSplitFeeModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const [step, setStep] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasSplitFee, setHasSplitFee] = React.useState(false)

  const [form, setForm] = React.useState<{
    total_split_fee_value: string
    split_fee_type: 'fixed' | 'percentage'
    split_fee_id: string
    pos_product_name: string
    pos_vendor_cnpj: string
    split_fee_value: number
    overpricing: number
    minimum_fee: number
  }>({
    total_split_fee_value: '',
    split_fee_type: 'fixed',
    split_fee_id: '',
    pos_product_name: '',
    pos_vendor_cnpj: '',
    split_fee_value: null,
    overpricing: null,
    minimum_fee: null,
  })

  const [formErrors, setFormErrors] = React.useState({
    total_split_fee_value: '',
  })

  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  useEffect(() => {
    if (isOpen) {
      checkIfHasSplitFee()

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: `modal_split_fee`,
        })
      }
    }
  }, [isOpen])

  const infoErrorHandler = (type, errors) => {
    setFormErrors(errors)
    const objKeysTranslation = {
      total_split_fee_value: 'Tarifa padrão',
    }
    const fieldsTranslated = Object.entries(errors)
      .map((error) =>
        error[1] !== '' ? objKeysTranslation[error[0]] : undefined
      )
      .filter((error) => error)
    if (type === 'emptyFields') {
      let requiredFields = ''
      if (fieldsTranslated.length > 2) {
        requiredFields = `${fieldsTranslated
          .slice(0, -1)
          .join(', ')} e ${fieldsTranslated.slice(-1)}`
      } else if (fieldsTranslated.length === 2) {
        requiredFields = `${fieldsTranslated.join(' e ')}`
      } else {
        requiredFields = fieldsTranslated[0]
      }
      setInfoError({
        title: 'Campo obrigatório não preenchido',
        message: (
          <>
            Preencha o campo de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }
  }

  const InfoBox: React.FC<{
    error?: { title: string; message: React.ReactElement }
  }> = ({ error }) => {
    if (error?.title) {
      return (
        <s.InfoBox error>
          <s.Text bold type="paragraph" color="redshipay">
            {error.title}
          </s.Text>
          <s.Text type="paragraph" color="cancelledStatusTextColor">
            {error.message}
          </s.Text>
        </s.InfoBox>
      )
    }

    return <></>
  }

  const checkIfHasSplitFee = async () => {
    setIsLoading(true)
    await get(baseURLRegistrationSummarySplitFee)
      .then((res) => {
        if (res.data.split_fee_id) {
          setForm({
            ...form,
            ...res.data,
            total_split_fee_value: formatMoney(
              res.data.split_fee_value.toFixed(2)
            ),
          })
          setHasSplitFee(true)
          setIsLoading(false)
          nextStep()
        } else {
          setForm({
            ...form,
            ...res.data,
          })
          setHasSplitFee(false)
          setIsLoading(false)
          nextStep()
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setForm({
          total_split_fee_value: form.total_split_fee_value
            ? form.total_split_fee_value
            : '',
          split_fee_type: 'fixed',
          split_fee_id: '',
          pos_product_name: '',
          pos_vendor_cnpj: '',
          split_fee_value: null,
          overpricing: null,
          minimum_fee: null,
        })
        setFormErrors({
          total_split_fee_value: '',
        })
        setInfoError({ title: '', message: <></> })
        setStep(0)
        setHasSplitFee(false)
        handleClose()
      })
  }

  const previousStep = () => {
    if (step > 1) {
      setStep(() => step - 1)
    }
  }

  const nextStep = () => {
    if (step < 2) {
      setStep(() => step + 1)
    }
  }

  const handleSplitFeeRequest = async () => {
    const errors = { ...formErrors }
    const {
      pos_product_name,
      pos_vendor_cnpj,
      split_fee_id,
      split_fee_type,
      split_fee_value,
      overpricing,
      minimum_fee,
      ...rest
    } = form

    for (const [key, value] of Object.entries(rest)) {
      if (!errors[key])
        errors[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }

    const errList = Object.values(errors).filter((x) => x !== '')

    if (!errList || errList.length === 0) {
      setInfoError({ title: '', message: <></> })

      setIsLoading(true)
      let url = hasSplitFee
        ? `${baseURLRegistrationV1SplitFee}/${form.split_fee_id}`
        : baseURLRegistrationV1SplitFee
      const payload = {
        split_fee_type: form.split_fee_type,
        total_split_fee_value: parseFloat(
          form.total_split_fee_value
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        ),
      }

      if (hasSplitFee) {
        await post(url, payload)
          .then((res) => {
            setIsLoading(false)
            nextStep()
          })
          .catch((e) => {
            setIsLoading(false)
            setInfoError({
              title: 'Erro ao atualizar tarifa',
              message: <>Não foi possível atualizar tarifa. Tente novamente.</>,
            })
          })
      } else {
        await post(url, payload)
          .then((res) => {
            setIsLoading(false)
            nextStep()
          })
          .catch((e) => {
            setIsLoading(false)
            setInfoError({
              title: 'Erro ao cadastrar tarifa',
              message: <>Não foi possível cadastrar tarifa. Tente novamente.</>,
            })
          })
      }
    } else {
      infoErrorHandler('emptyFields', errors)
    }
  }

  const renderContent = (step: number) => {
    if (isLoading) {
      return (
        <Loading data-testid="loading" step={step} hasSplitFee={hasSplitFee} />
      )
    }

    if (step === 1 && !isLoading) {
      return (
        <s.Sections>
          <s.SectionFormWrapper data-testid="form-default-split-fee">
            <s.Section>
              <InfoBox error={infoError} />

              <s.HeaderWrapper>
                <s.Icon fill="graytheme8" name="payments" />
                <s.Text
                  bold
                  margin="0 0 0 8px"
                  color="graytheme6"
                  type="headline"
                >
                  Tarifa padrão
                </s.Text>
              </s.HeaderWrapper>
            </s.Section>
            <s.Text
              margin="0 0 32px 0"
              fontWeight={400}
              type="paragraph"
              color="graytheme6"
            >
              Tarifa que será descontada do lojista por Pix liquidado. A tarifa
              padrão incidirá sobre todos os clientes da automação, sendo
              possível personalizar uma tarifa para um cliente específico
              acessando a página de Pix, na área do cliente.
            </s.Text>
            <s.Line />
            <s.Section>
              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  color={
                    formErrors.total_split_fee_value
                      ? 'redshipay'
                      : 'graytheme6'
                  }
                >
                  Tarifa padrão
                </s.Text>
                <s.InputText
                  width={260}
                  testId="form-total-split-fee-value"
                  value={form.total_split_fee_value}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      total_split_fee_value: formatMoney(e.target.value),
                    })
                    if (!!formErrors.total_split_fee_value) {
                      setFormErrors({
                        ...formErrors,
                        total_split_fee_value: '',
                      })
                    }
                  }}
                  error={
                    formErrors.total_split_fee_value
                      ? { message: formErrors.total_split_fee_value }
                      : false
                  }
                  suffix={
                    formErrors.total_split_fee_value ? (
                      <s.Icon name="alertcirclefilled" fill="lightred2" />
                    ) : (
                      <></>
                    )
                  }
                />
              </s.SectionLine>
              <s.SectionLine>
                <s.Text fontWeight={600} type="headline">
                  Nome da automação
                </s.Text>
                <s.InputText
                  width={260}
                  testId="form-pos-product-name"
                  value={form.pos_product_name}
                  disabled={true}
                />
              </s.SectionLine>
              <s.SectionLine>
                <s.Text fontWeight={600} type="headline">
                  CNPJ da automação
                </s.Text>
                <s.InputText
                  width={260}
                  testId="form-pos-vendor-cnpj"
                  value={applyCnpjMask(form.pos_vendor_cnpj)}
                  disabled={true}
                />
              </s.SectionLine>
            </s.Section>
          </s.SectionFormWrapper>
        </s.Sections>
      )
    }
    if (step === 2 && !isLoading) {
      return <Success hasSplitFee={hasSplitFee} />
    }
  }

  const renderButtons = (step: number) => {
    if (step === 0) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 1 && !isLoading) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid=""
              onClick={() => {
                setStep(0)
                handleClose()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Text color="graytheme8" type="paragraph">
                Fechar
              </s.Text>
            </s.Button>

            <s.Button
              data-testid="submit-fee-button"
              width="304px"
              height="42px"
              onClick={() => handleSplitFeeRequest()}
            >
              <s.Text type="paragraph" color="white">
                Confirmar configurações
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 1 && isLoading) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    return (
      <s.PreviousNextButtonContainer>
        <s.Div></s.Div>
        <s.PreviousNextButtonWrapper>
          <s.Button
            data-testid="success-button"
            width="100%"
            height="42px"
            color="whitedetailed"
            onClick={() => handleClose()}
          >
            <s.Text margin="0 8px 0 0" color="graytheme8" type="headline">
              Fechar
            </s.Text>
          </s.Button>
        </s.PreviousNextButtonWrapper>
      </s.PreviousNextButtonContainer>
    )
  }

  return (
    <>
      <s.Modal
        handleClose={handleClose}
        isOpen={isOpen}
        modalSubtitle="Gerenciamento de splits"
      >
        <s.ModalContent data-testid="default-split-modal">
          {renderContent(step)}
          {renderButtons(step)}
        </s.ModalContent>
      </s.Modal>
    </>
  )
}
