import React from 'react'
import { useHistory } from 'react-router'

import { v4 as uuid } from 'uuid'

import useGetStores from 'hooks/useGetStores'
import useDebounce from 'hooks/useDebounce'
import UseStore from 'hooks/useStore'
import { useNonInitialEffect } from 'hooks/utils'

import CreateStoreModal from './CreateStoreModal'
import EditStoreModal from './EditStoreModal'

import Toast from 'components/Toast'

import * as s from './styles'

const Stores: React.FC = () => {
  const history = useHistory()
  const [selected, setSelected] = React.useState({ id: 1, name: 'Nome' })
  const [searchText, setSearchText] = React.useState('')
  const [displaySearchText, setDisplaySearchText] = React.useState('')
  const [offset, setOffset] = React.useState<string>('0')
  const debouncedSearchText = useDebounce(setSearchText, 1000)
  const [isActive, setIsActive] = React.useState(true)
  const [createStoreModalIsOpen, setCreateStoreModalIsOpen] =
    React.useState(false)
  const [editStoreModalIsOpen, setEditStoreModalIsOpen] = React.useState(false)
  const [editStoreId, setEditStoreId] = React.useState('')
  const { stores, hasError, isLoading, loadStores } = useGetStores()
  const {
    deleteResponse,
    deleteIsLoading,
    deleteStore,
    isLoading: isLoadingStore,
    postIsLoading,
    postResponse,
    reactivateStore,
  } = UseStore()

  React.useEffect(() => {
    loadStores({
      limit: '40',
      offset: offset,
      filterby: searchText,
      column_name: selected.name === 'Nome' ? 'store_name' : 'store_cnpj_cpf',
      active: isActive,
    })
  }, [isActive, searchText, offset])

  React.useEffect(() => {
    if (deleteResponse?.code === 200) {
      loadStores({
        limit: '40',
        offset: '0',
        filterby: searchText,
        column_name: selected.name === 'Nome' ? 'store_name' : 'store_cnpj_cpf',
        active: isActive,
      })
    }
  }, [deleteResponse])

  useNonInitialEffect(() => {
    if (postResponse) {
      loadStores({
        limit: '40',
        offset: '0',
        filterby: searchText,
        column_name: selected.name === 'Nome' ? 'store_name' : 'store_cnpj_cpf',
        active: isActive,
      })
    }
  }, [postResponse])

  const storesRender = () => {
    if (isLoading) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-stores"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    }
    if (hasError) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    }
    if (stores.total === 0) {
      return (
        <s.Text type="heading3" color="maincolor">
          Parece que não há registros! Tente novamente
        </s.Text>
      )
    }
    if (stores.total > 0) {
      return stores.data.map((store) => (
        <s.Card width={400} height={180} isActive={store.active} key={uuid()}>
          <s.ClickableText
            onClick={() => history.push(`/lojas/${store.id}`)}
            bold
            type="heading4"
          >
            {store.name}
          </s.ClickableText>
          <s.ButtonsWrapper>
            <s.Button
              disabled={deleteIsLoading || isLoadingStore || postIsLoading}
              onClick={() => {
                if (store.active === true) {
                  deleteStore(store.id)
                } else {
                  reactivateStore(store.id)
                }
              }}
              width="100px"
              color="transparent"
              outline
            >
              {store.active === true ? 'inativar' : 'reativar'}
            </s.Button>
            {deleteIsLoading || isLoadingStore || postIsLoading ? (
              <s.Loading
                type="spinner"
                data-testid="loading-spinner1"
                color="maincolor"
              />
            ) : null}
            <s.Button
              disabled={deleteIsLoading || isLoadingStore || postIsLoading}
              width="100px"
              color="primary"
              onClick={() => {
                setEditStoreModalIsOpen(true)
                setEditStoreId(store.id)
              }}
            >
              editar
            </s.Button>
          </s.ButtonsWrapper>
        </s.Card>
      ))
    }
  }

  return (
    <s.Container>
      <Toast />
      <CreateStoreModal
        isOpen={createStoreModalIsOpen}
        handleClose={() => {
          setCreateStoreModalIsOpen(false)
        }}
      />
      <EditStoreModal
        isOpen={editStoreModalIsOpen}
        storeId={editStoreId}
        handleClose={() => {
          setEditStoreModalIsOpen(false)
          setEditStoreId('')
        }}
      />
      <s.HeaderWrapper>
        <s.Text type="heading3" color="maincolor">
          Lojas
        </s.Text>
        <s.Button
          color="transparent"
          outline
          onClick={() => {
            setCreateStoreModalIsOpen(true)
            if (window.gtag) {
              window.gtag('event', 'page_view', {
                page_path: `modal_create_store`,
              })
            }
          }}
        >
          + Adicionar loja
        </s.Button>
      </s.HeaderWrapper>
      <s.SearchWrapper>
        <s.Select
          label="buscar por:"
          options={[
            {
              id: 1,
              text: 'Nome',
              onClick: () => {
                setSelected({ id: 1, name: 'Nome' })
                setDisplaySearchText('')
                debouncedSearchText('')
              },
            },
            {
              id: 2,
              text: 'CPF/CNPJ',
              onClick: () => {
                setSelected({ id: 2, name: 'CPF/CNPJ' })
                setDisplaySearchText('')
                debouncedSearchText('')
              },
            },
          ]}
          selected={selected}
        />
        <s.SearchInputText
          width={400}
          value={displaySearchText}
          onChange={(e) => {
            setDisplaySearchText(e.target.value)
            debouncedSearchText(e.target.value)
          }}
          placeholder={selected.name}
        />
        <s.StoreActiveButton
          onClick={() => {
            setIsActive(!isActive)
          }}
          color="transparent"
          outline
        >
          {isActive ? 'lojas inativas' : 'lojas ativas'}
        </s.StoreActiveButton>
      </s.SearchWrapper>
      <s.StoresWrapper>{storesRender()}</s.StoresWrapper>
      {stores.total > 0 ? (
        <s.Pagination
          count={50}
          offset="0"
          total={stores.total}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      ) : null}
    </s.Container>
  )
}

export default Stores
