import { showToast } from 'components/Toast'
import RepresentantConfirmation from './RepresentantConfirmation'
import UnableToEnableAccount from './UnableToEnableAccount'
import KBAQuestionsAndAnswers, {
  KBAQuestionsAndAnswersProps,
} from './KBAQuestionsAndAnswers'
import Success from './Success'
import Error from './Error'
import { useEffect, useState } from 'react'
import * as s from './styles'
import { OnRegisterStep } from './OnRegisterStep'
import RegisterPixRequestSent from './RegisterPixRequestSent'
import UserVerificationIntroduction from './UserVerificationIntroduction'
import KBAUserVerficationFailed from './KBAUserVerificationFailed'
import UserVerificationDocumentSelection from './UserVerificationDocumentSelection'
import LoadingOnDocumentSent from './LoadingOnDocumentSent'
import DocumentVerificationUnavailable from './DocumentVerificationUnavailable'
import DocumentoscopyUncertaintyDetected from './DocumentoscopyUncertaintyDetected'

import DocumentVerification from './DocumentVerification'
import { fileToBase64 } from 'helpers'
import { baseURLVerifyUsersV1, post } from 'services/api'
interface IProps {
  isOpen: string
  handleClose: () => any
  userVerificationStatus?: 'unverified' | 'verified' | 'pending_verification'
}

export const INFO_INITIAL_STATE = {
  client_id: '',
  client_secret: '',
  name: '',
  document_number: '',
  cpf: '',
  account_id: '',
}

export const STEP_INITIAL_STATE = {
  onRegisterStep: false,
  onLoading: false,
  success: false,
  apiError: false,
  legalRepresentantConfirmation: false,
  KBAIntroduction: false,
  KBAQuestionsAndAnswers: false,
  KBAUserVerificationFailed: false,
  userVerificationDocumentSelection: false,
  loadingOnDocumentSent: false,
  validDocumentscopyStatus: false,
  invalidDocumentscopyStatus: false,
  uncertainDocumentscopyStatus: false,
  unavailableDocumentscopyStatus: false,
  documentVerification: false,
  notAllowed: false,
  registerPixRequestSent: false,
  requestSent: false,
}

export type Info = typeof INFO_INITIAL_STATE
export type Steps = typeof STEP_INITIAL_STATE
type DocumentType = 'rg' | 'cnh' | ''

export const WalletSettingsFormTbanksCashout: React.FC<IProps> = ({
  isOpen,
  handleClose,
  userVerificationStatus,
}) => {
  const [step, setStep] = useState(STEP_INITIAL_STATE)
  const [info, setInfo] = useState(INFO_INITIAL_STATE)
  const [docType, setDocType] = useState<DocumentType>('')
  const [questions, setQuestions] = useState<KBAQuestionsAndAnswersProps>({
    questionnaire_id: '',
    questions: [],
  })
  const [documentscopyStatus, setDocumentscopyStatus] = useState<
    'valid' | 'invalid' | 'uncertain' | 'error' | 'valid_with_error' | ''
  >('')

  async function submitDocuments(frontImage, backImage) {
    const front = await fileToBase64(frontImage).then((img: string) => {
      return img.split('base64,')[1]
    })

    const back = await fileToBase64(backImage).then((img: string) => {
      return img.split('base64,')[1]
    })

    setStep({ ...STEP_INITIAL_STATE, loadingOnDocumentSent: true })
    await post(`${baseURLVerifyUsersV1}/documentscopy`, {
      document_image_front: front,
      document_image_back: back,
    })
      .then((res) => {
        setDocumentscopyStatus('valid')
        showToast({
          type: 'success',
          message: 'Usuário verificado com sucesso',
        })
        handleClose()
      })
      .catch((e) => {
        if (e.response.data?.documentscopy_status == 'valid')
          setDocumentscopyStatus('valid_with_error')
      })
  }

  useEffect(() => {
    if (!userVerificationStatus) {
      setStep({
        ...STEP_INITIAL_STATE,
        onRegisterStep: true,
      })
    }

    if (userVerificationStatus) {
      if (userVerificationStatus === 'unverified') {
        setStep({
          ...STEP_INITIAL_STATE,
          legalRepresentantConfirmation: true,
        })
      }
      if (userVerificationStatus === 'pending_verification') {
        setStep({
          ...STEP_INITIAL_STATE,
          registerPixRequestSent: true,
        })
      }
    }

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_create_tbanks_cashout`,
      })
    }
  }, [userVerificationStatus])

  const goToStores = () => {
    setInfo(INFO_INITIAL_STATE)
    setStep(STEP_INITIAL_STATE)
  }

  const closeModal = () => {
    setInfo(INFO_INITIAL_STATE)
    setStep(STEP_INITIAL_STATE)
    handleClose()
  }

  const handleModalTitles = ({
    onRegisterStep,
    registerPixRequestSent,
    success,
    apiError,
    legalRepresentantConfirmation,
    notAllowed,
    KBAIntroduction,
    KBAQuestionsAndAnswers,
    KBAUserVerificationFailed,
    userVerificationDocumentSelection,
    loadingOnDocumentSent,
    validDocumentscopyStatus,
    invalidDocumentscopyStatus,
    unavailableDocumentscopyStatus,
    documentVerification,
    uncertainDocumentscopyStatus,
  }: Steps): string => {
    if (onRegisterStep || success || apiError) {
      return 'Cadastrar Conta Pix'
    }

    if (notAllowed || registerPixRequestSent || legalRepresentantConfirmation) {
      return 'Habilitar Conta Pix'
    }
    if (
      KBAIntroduction ||
      KBAQuestionsAndAnswers ||
      KBAUserVerificationFailed ||
      userVerificationDocumentSelection ||
      loadingOnDocumentSent ||
      validDocumentscopyStatus ||
      invalidDocumentscopyStatus ||
      uncertainDocumentscopyStatus ||
      documentVerification
    ) {
      return 'Verificar usuário'
    }

    return ''
  }
  const renderContent = () => {
    if (step.success) {
      return <Success goToStores={goToStores} />
    }

    if (step.apiError) {
      return <Error setStep={setStep} setInfo={setInfo} />
    }

    if (step.notAllowed) {
      return (
        <UnableToEnableAccount
          cnpj={info.document_number}
          closeModal={closeModal}
        />
      )
    }

    if (step.registerPixRequestSent) {
      return <RegisterPixRequestSent closeModal={closeModal} />
    }

    if (step.legalRepresentantConfirmation) {
      return (
        <RepresentantConfirmation
          answer={(agree: boolean) =>
            agree
              ? setStep({
                  ...STEP_INITIAL_STATE,
                  KBAIntroduction: true,
                })
              : setStep({
                  ...STEP_INITIAL_STATE,
                  notAllowed: true,
                })
          }
          info={info}
          setInfo={setInfo}
        />
      )
    }

    if (step.KBAIntroduction) {
      return (
        <UserVerificationIntroduction
          cpf={info.cpf}
          setQuestions={setQuestions}
          step={STEP_INITIAL_STATE}
          setStep={setStep}
        />
      )
    }

    if (step.KBAQuestionsAndAnswers) {
      if (questions) {
        return (
          <KBAQuestionsAndAnswers
            questionnaire_id={questions.questionnaire_id}
            questions={questions.questions}
            step={STEP_INITIAL_STATE}
            setStep={setStep}
          />
        )
      }
    }
    if (step.KBAUserVerificationFailed) {
      return (
        <KBAUserVerficationFailed step={STEP_INITIAL_STATE} setStep={setStep} />
      )
    }

    if (step.userVerificationDocumentSelection) {
      return (
        <UserVerificationDocumentSelection
          docType={(type: DocumentType) => {
            setDocType(type)
            setStep({ ...STEP_INITIAL_STATE, documentVerification: true })
          }}
        />
      )
    }

    if (step.loadingOnDocumentSent) {
      return (
        <LoadingOnDocumentSent
          documentscopyStatus={documentscopyStatus}
          step={STEP_INITIAL_STATE}
          setStep={setStep}
        />
      )
    }

    if (step.documentVerification) {
      return (
        <DocumentVerification
          step={STEP_INITIAL_STATE}
          setStep={setStep}
          docType={docType}
          submitDocuments={submitDocuments}
        />
      )
    }

    if (step.unavailableDocumentscopyStatus) {
      return (
        <DocumentVerificationUnavailable
          step={STEP_INITIAL_STATE}
          setStep={setStep}
        />
      )
    }

    if (step.uncertainDocumentscopyStatus) {
      return (
        <DocumentoscopyUncertaintyDetected
          step={STEP_INITIAL_STATE}
          setStep={setStep}
        />
      )
    }

    return (
      <OnRegisterStep
        info={info}
        setInfo={setInfo}
        setStep={setStep}
        closeModal={closeModal}
      />
    )
  }

  return (
    <s.Modal
      handleClose={() => handleClose()}
      isOpen={!!isOpen}
      modalSubtitle={handleModalTitles(step)}
    >
      <s.ModalContent data-testid="settings-modal-content">
        {renderContent()}
      </s.ModalContent>
    </s.Modal>
  )
}
